.main-navbar {
  position: fixed;
  @extend .w-100;
  padding: rem(24px) 0;
  z-index: 99;
  top: 0;
  @extend .transition;
  @include media(1199px) {
    padding: rem(12px) 0;
  }
  &.navbar-active {
    top: -100%;
  }
  &.navbar-scroll-bg {
    background-color: $section-bg !important;
    border-bottom: 1px solid rgba(#fff, 0.1);
  }
  &::before {
    @extend .position-absolute;
    content: "";
    top: 0;
    left: 0;
    @extend .w-100;
    @extend .h-100;
    background-color: rgba(7, 17, 38, 0.59);
    backdrop-filter: blur(6.5px);
    @extend .z-index-c;
  }
  .navbar-brand {
    img {
      max-height: 42px;
      max-width: 200px;
    }
  }
  &.multi-home-navbar {
    &::before {
      display: none;
    }
    .navbar-collapse {
      @include media(1199px) {
        padding: rem(20px);
        background-color: $section-bg;
      }
    }
  }
  &.multi-home-navbar-two {
    background-color: #071126;
  }
  &.multi-home-navbar-three {
    background-color: transparent;
    &::before {
      display: none;
    }
    .navbar-collapse {
      @include media(1199px) {
        padding: rem(20px);
        background-color: $section-bg;
      }
    }
  }
}

.site-menu {
  .menu-item {
    @extend .position-relative;
    @include media(1199px) {
      border-bottom: 1px solid rgba(#fff, 0.15);
    }
    &:hover {
      .sub-menu {
        left: 0;
        opacity: 1;
        visibility: visible;
        li {
          margin-left: 0;
        }
      }
    }
    a {
      padding: rem(10px) rem(20px);
      font-family: $heading-font;
      font-size: rem(18px);
      font-weight: 500;
      @extend .text-white;
      @extend .transition;
      @include media(1199px) {
        padding-left: 0;
      }
      &:hover {
        @extend .text-base;
      }
    }
    .sub-menu {
      @extend .position-absolute;
      top: 100%;
      left: -15px;
      width: 240px;
      background-color: lighten($body-bg, 5%);
      padding: rem(10px) 0;
      opacity: 0;
      visibility: hidden;
      @extend .transition;
      @include media(1199px) {
        position: static;
        opacity: 1;
        visibility: visible;
        width: 100%;
        display: none;
        transition: none;
      }
      li {
        margin-left: -15px;
        @extend .transition;
        border-bottom: none;
        @include media(1199px) {
          margin-left: 0;
          transition: none;
        }
        a {
          padding: rem(6px) rem(20px);
        }
      }
    }
  }
  .menu-item-has-children {
    .menu-item-button {
      @extend .position-absolute;
      top: 7px;
      right: 0;
      width: 35px;
      height: 35px;
      display: none;
      @extend .justify-center;
      @extend .align-center;
      background-color: rgba(#fff, 0.15);
      @extend .text-white;
      font-size: rem(22px);
      z-index: 9;
      @media screen and (max-width: 1199px) {
        display: inline-flex;
        transition: none;
      }
    }
  }
}

.navbar-collapse {
  @include media(1199px) {
    margin-top: rem(20px);
  }
}

.navbar-toggler {
  &:focus,
  &:active {
    box-shadow: none;
    outline: none;
  }
  &[aria-expanded="true"] {
    .navbar-toggler-icon {
      border-top: none;
      border-bottom: none;
      &::before {
        @include transform(rotate(45deg));
      }
      &::after {
        @include transform(rotate(-45deg));
      }
    }
  }
}

.navbar-toggler-icon {
  @extend .position-relative;
  background-image: none;
  width: 30px;
  height: 20px;
  background-color: transparent;
  padding: 0;
  border-top: 1px solid #fff;
  border-bottom: 1px solid #fff;
  &::before,
  &::after {
    @extend .position-absolute;
    content: "";
    top: 50%;
    left: 0;
    @extend .w-100;
    height: 1px;
    background-color: #fff;
    @extend .transition;
  }
}
