.inner-page-banner {
  padding-top: rem(240px);
  padding-bottom: rem(135px);
  @extend .position-relative;
  background-size: cover;
  background-repeat: no-repeat;
  @extend .z-index-p;
  @include media(1399px) {
    padding-top: rem(180px);
    padding-bottom: rem(85px);
  }
  @include media(991px) {
    padding-top: rem(120px);
    padding-bottom: rem(55px);
  }
  &::before {
    @extend .position-absolute;
    content: "";
    top: 0;
    left: 0;
    @extend .w-100;
    @extend .h-100;
    background: rgba(11, 24, 52, 0.53);
    @extend .z-index-c;
  }
  .inner-page-title {
    font-size: rem(76px);
    font-weight: 700;
    @include media(1399px) {
      font-size: rem(56px);
    }
    @include media(991px) {
      font-size: rem(42px);
    }
    @include media(575px) {
      font-size: rem(32px);
    }
  }
  &.style-two {
    &::before {
      background: rgba(11, 24, 52, 0.67);
    }
  }
}

.page-breadcrumb {
  @extend .d-flex;
  @extend .justify-center;
  @extend .align-center;
  margin-top: rem(20px);
  li {
    @extend .text-white;
    &:last-child {
      &::after {
        display: none;
      }
    }
    &::after {
      content: "-";
      padding: 0 rem(15px);
    }
    a {
      @extend .text-white;
      &:hover {
        @extend .text-base;
      }
    }
  }
}
