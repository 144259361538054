h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: $heading-font;
  color: $heading-color;
  font-weight: 600;
  margin: 0;
  line-height: 1.3;
  word-break: break-word;
}
h1 > a,
h2 > a,
h3 > a,
h4 > a,
h5 > a,
h6 > a {
  // font-family: $heading-font;
  color: $heading-color;
  // font-weight: 700;
  @include transition(all 0.3s);
  line-height: 1.4;
  word-break: break-word;
}
h1 {
  font-size: rem(76px);
}
h2 {
  font-size: rem(57px);
  @include media(1399px) {
    font-size: rem(42px);
  }
  @include media(1199px) {
    font-size: rem(36px);
  }
  @include media(991px) {
    font-size: rem(32px);
  }
  @include media(575px) {
    font-size: rem(28px);
  }
}
h3 {
  font-size: rem(43px);
  @include media(1399px) {
    font-size: rem(36px);
  }
  @include media(1199px) {
    font-size: rem(32px);
  }
  @include media(991px) {
    font-size: rem(24px);
  }
}
h4 {
  font-size: rem(32px);
  @include media(1399px) {
    font-size: rem(28px);
  }
  @include media(1199px) {
    font-size: rem(24px);
  }
  @include media(991px) {
    font-size: rem(22px);
  }
}
h5 {
  font-size: rem(24px);
  @include media(1399px) {
    font-size: rem(22px);
  }
  @include media(991px) {
    font-size: rem(20px);
  }
}
h6 {
  font-size: rem(18px);
}
p,
li,
span {
  margin: 0;
}
a {
  text-decoration: none;
  display: inline-block;
  font-family: $para-font;
  font-weight: 400;
}
a:hover {
  text-decoration: none;
}
strong {
  font-weight: 500;
}

.fs\[24px\] {
  font-size: rem(24px) !important;
  @include media(1399px) {
    font-size: rem(22px) !important;
  }
  @include media(1199px) {
    font-size: rem(20px) !important;
  }
  @include media(575px) {
    font-size: rem(18px) !important;
  }
}

.fs\[18px\] {
  font-size: rem(18px) !important;
}
.fs\[16px\] {
  font-size: rem(16px) !important;
}
.fs\[15px\] {
  font-size: rem(15px) !important;
}
.fs\[14px\] {
  font-size: rem(14px) !important;
}
.fs\[12px\] {
  font-size: rem(12px) !important;
}
.h-font {
  font-family: $heading-font !important;
}
.p-font {
  font-family: $para-font !important;
}
.fw-medium {
  font-weight: 600 !important;
}

.fw-500 {
  font-weight: 500 !important;
}

.fw-600 {
  font-weight: 600 !important;
}
