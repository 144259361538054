// podcast item css start
.podcast-item {
  &:hover {
    .podcast-item-thumb {
      img {
        @include transform(scale(1.15, 1.15));
      }
    }
    .show-name {
      @extend .text-base;
    }
  }
  &-thumb {
    @extend .position-relative;
    height: 460px;
    @extend .overflow-hidden;
    z-index: 1;
    @include media(1399px) {
      height: 420px;
    }
    &::before {
      @extend .position-absolute;
      content: "";
      bottom: 0;
      left: 0;
      @extend .w-100;
      @extend .h-100;
      background: linear-gradient(
        180deg,
        rgba(0, 0, 0, 0) 53.9%,
        rgba(0, 0, 0, 0.514817) 69.95%,
        rgba(0, 0, 0, 0.809765) 83.1%,
        #000000 100%
      );
      z-index: 1;
    }
    img {
      @extend .w-100;
      @extend .h-100;
      @extend .obj-fit;
      @extend .transition;
      z-index: -2;
    }
    .thumb-content {
      @extend .position-absolute;
      bottom: 0;
      left: 0;
      padding: rem(35px);
      z-index: 2;
      p {
        font-family: $top-font;
        font-weight: 600;
        font-size: rem(32px);
        line-height: 1.3;
        @extend .text-white;
        margin-bottom: rem(14px);
        @include media(1399px) {
          font-size: rem(26px);
        }
        @include media(575px) {
          font-size: rem(24px);
        }
        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
  &-content {
    margin-top: rem(25px);
    @extend .d-flex;
    @extend .align-center;
    .artist-thumb {
      width: 86px;
      height: 86px;
      @extend .overflow-hidden;
      img {
        @extend .w-100;
        @extend .h-100;
        @extend .obj-fit;
      }
    }
    .artist-content {
      width: calc(100% - 86px);
      padding-left: rem(30px);
      .show-name {
        font-weight: 500;
      }
      .artist-name {
        font-size: rem(18px);
        margin-top: rem(10px);
      }
    }
  }
  &.style-two {
    @extend .position-relative;
    .podcast-item-thumb {
      height: 258px;
      &::before {
        background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000000 100%);
      }
    }
    .thumb-content {
      bottom: auto;
      top: 20px;
      left: 20px;
      padding: 0;
      p {
        font-size: rem(18px);
        font-weight: 500;
        font-family: $para-font;
      }
    }
    .podcast-item-content {
      @extend .position-absolute;
      bottom: 0;
      left: 0;
      @extend .w-100;
      padding: rem(20px);
      z-index: 2;
      .artist-thumb {
        width: 60px;
        height: 60px;
      }
      .artist-content {
        width: calc(100% - 60px);
        .show-name {
          font-weight: 400;
          font-family: $para-font;
        }
        .artist-name {
          margin-top: rem(5px);
          font-size: rem(16px);
          @extend .text-white;
        }
      }
    }
  }
  &.style-three {
    .podcast-item-thumb {
      height: 300px;
      @include border-radius(30px);
      overflow: hidden;
      &::before {
        display: none;
      }
    }
    .podcast-item-content {
      .artist-thumb {
        width: 70px;
        height: 70px;
        @include border-radius(15px);
        background-color: #28344c;
        display: flex;
        justify-content: center;
        align-items: center;
        img {
          width: auto;
          height: auto;
        }
      }
      .artist-content {
        padding-left: rem(15px);
        .show-name {
          font-weight: 400;
        }
        .artist-name {
          margin-top: rem(3px);
        }
      }
    }
  }
  &.podcast-item-dark-bg {
    padding: rem(20px);
    background-color: #0b1834;
    @include border-radius(30px);
    .podcast-item-thumb {
      @include border-radius(20px);
      height: 230px;
    }
  }
}

.left-el,
.right-el {
  @extend .position-absolute;
  z-index: -1;
}

.left-el {
  top: 155px;
  left: 0;
}

.right-el {
  top: 0;
  right: 0;
}

.show-tab-content {
  margin-top: rem(55px);
}

.podcast-slider {
  padding-bottom: rem(75px);
  .slick-list {
    margin: 0 rem(-15px);
  }
  .single-slide {
    padding: 0 rem(15px);
  }
  .slick-arrow {
    @extend .position-absolute;
    bottom: 0;
    width: 28px;
    height: 28px;
    @extend .bg-main;
    color: #000000;
    @extend .d-flex;
    @extend .align-center;
    @extend .justify-center;
    &.prev {
      left: 50%;
      margin-left: -40px;
    }
    &.next {
      right: 50%;
      margin-right: -40px;
    }
  }
}

.podcast-slider-two {
  padding-bottom: rem(75px);
  @include media(991px) {
    padding-bottom: rem(45px);
  }
  .slick-list {
    margin: rem(-7px) rem(-12px);
  }
  .single-slide {
    padding: rem(7px) rem(12px);
  }
  .slick-arrow {
    @extend .position-absolute;
    bottom: 0;
    width: 28px;
    height: 28px;
    @extend .bg-main;
    color: #000000;
    @extend .d-flex;
    @extend .align-center;
    @extend .justify-center;
    &.prev {
      left: 50%;
      margin-left: -40px;
    }
    &.next {
      right: 50%;
      margin-right: -40px;
    }
  }
}

.podcard-show-item {
  padding: rem(30px);
  // background-color: $body-bg;
  background-color: #0b1834;
  .icon {
    width: 70px;
    height: 70px;
    @extend .site-color;
    @extend .bs-50;
    margin-bottom: rem(23px);
    @extend .d-flex;
    @extend .items-center;
  }
  .podcard-show-title {
    font-weight: 500;
    margin-bottom: rem(20px);
  }
  .podcard-show-link {
    @extend .text-base;
    margin-top: rem(20px);
    @extend .position-relative;
    &::after {
      @extend .position-absolute;
      content: "";
      top: 50%;
      right: -40px;
      width: 26px;
      height: 1px;
      @extend .site-color;
    }
    &::before {
      @extend .position-absolute;
      content: "";
    }
  }
}
// podcast item css end

.multi-popular-left-el {
  @extend .position-absolute;
  left: -8px;
  bottom: 40px;
  @extend .z-index-c;
}

.popular-show-slider-wrapper {
  width: calc(100% + 405px);
  @include media(575px) {
    width: 100%;
  }
}

.popular-show-prev {
  margin-right: rem(20px);
}

.popular-show-prev,
.popular-show-next {
  @extend .site-color;
  width: 28px;
  height: 28px;
  @include border-radius(5px);
  font-size: rem(14px);
}

.popular-show-slider {
  .slick-list {
    margin: 0 rem(-15px);
  }
  .single-slide {
    padding: 0 rem(15px);
  }
  .slick-arrow {
    @extend .position-absolute;
    top: -75px;
    width: 28px;
    height: 28px;
    @extend .bg-main;
    color: #000000;
    @extend .d-flex;
    @extend .align-center;
    @extend .justify-center;
    &.prev {
      right: 50px;
    }
    &.next {
      right: 0;
    }
  }
}
