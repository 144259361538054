.blog-details-wrapper {
  .blog-details-content {
    margin-top: rem(30px);
  }
  .blog-details-title {
    font-size: rem(57px);
    font-weight: 700;
    margin-top: rem(30px);
    margin-bottom: rem(30px);
    @include media(1399px) {
      font-size: rem(42px);
    }
    @include media(991px) {
      font-size: rem(32px);
    }
  }
  &.style-two {
    .blog-details-thumb {
      img {
        @include border-radius(30px);
      }
    }
  }
}

.blog-details-footer {
  overflow: hidden;
  padding: rem(20px) 0;
  border-top: 1px solid #28344c;
  border-bottom: 1px solid #28344c;
  @extend .d-flex;
  justify-content: space-between;
  .social-links-white {
    margin: rem(-10px) rem(-15px);
    li {
      padding: 0 rem(5px);
      a {
        padding: rem(10px);
      }
    }
  }
}

.comment-area {
  margin-top: rem(60px);
  .single-comment + .single-comment {
    margin-top: rem(30px);
  }
  .single-comment {
    .single-comment-inner {
      padding: rem(30px);
      background-color: $body-bg;
      @extend .d-flex;
      @include media(575px) {
        padding: rem(20px);
      }
      .single-comment-thumb {
        width: 65px;
        height: 65px;
        @extend .bs-50;
        @extend .overflow-hidden;
      }
      .single-comment-content {
        width: calc(100% - 65px);
        padding-left: rem(20px);
        @include media(575px) {
          width: 100%;
          padding-left: 0;
          margin-top: rem(15px);
        }
      }
    }
    .single-comment {
      margin-top: rem(30px);
      padding-left: rem(110px);
      @include media(767px) {
        padding-left: rem(50px);
      }
    }
  }
}

.comment-form-area {
  margin-top: rem(60px);
  .form-control {
    background-color: $body-bg;
    @include placeholder(#d9d9d9);
  }
}

blockquote {
  @extend .position-relative;
  background-color: #0b1834;
  padding: rem(20px) rem(20px) rem(20px) rem(40px);
  @include border-radius(20px);
  &::after {
    @extend .position-absolute;
    content: "";
    top: 20px;
    left: 20px;
    width: 2px;
    height: calc(100% - 40px);
    @extend .site-color;
  }
}
