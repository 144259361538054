.subscribe-section {
  padding-top: rem(73px);
  padding-bottom: rem(80px);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  @include media(1399px) {
    padding-top: rem(63px);
    padding-bottom: rem(70px);
  }
  @include media(575px) {
    padding-top: rem(45px);
    padding-bottom: rem(50px);
  }
  .section-title {
    font-size: rem(43px);
    @include media(1399px) {
      font-size: rem(36px);
    }
    @include media(991px) {
      font-size: rem(32px);
    }
    @include media(575px) {
      font-size: rem(26px);
    }
  }
  .subscribe-section-des {
    margin-top: rem(25px);
    @include media(1399px) {
      margin-top: rem(15px);
    }
  }
  &.style-two {
    @extend .position-relative;
    @extend .overflow-hidden;
    .side-img {
      @extend .position-absolute;
      top: 0;
      right: 0;
      width: 45%;
      @extend .h-100;
      @include media(991px) {
        display: none;
      }
      img {
        @extend .w-100;
        @extend .h-100;
        @extend .obj-fit;
      }
    }
  }
}

.subscribe-form {
  @extend .d-flex;
  margin-top: rem(60px);
  @include media(1399px) {
    margin-top: rem(40px);
  }
  &.style-two {
    .form-control,
    .btn {
      @include border-radius(999px);
    }
    .form-control {
      background-color: #fff;
    }
  }
  .form-control {
    padding: rem(20px) rem(30px);
    height: 90px;
    width: auto;
    flex-grow: 1;
    background: rgba(#fff, 0.78);
    backdrop-filter: blur(4px);
    @include border-radius(0);
    font-size: rem(18px);
    @include placeholder(#444);
    color: #2b2b2b;
    @include media(1399px) {
      height: 70px;
    }
    @include media(575px) {
      width: 100%;
      height: 50px;
      font-size: rem(16px);
    }
  }
  .btn {
    width: 192px;
    margin-left: rem(12px);
    @include media(575px) {
      width: 100%;
      height: 50px;
      margin-top: rem(15px);
      margin-left: 0;
    }
  }
}
