/* reset css start */
// @import url('https://fonts.googleapis.com/css2?family=Lexend:wght@500;600;700&family=Roboto:wght@400;500&display=swap');

@import url("https://fonts.googleapis.com/css2?family=Lexend:wght@500;600;700&family=Oswald:wght@600&family=Roboto:wght@400;500&display=swap");

$heading-font: "Lexend", sans-serif;
$para-font: "Roboto", sans-serif;
$top-font: "Oswald", sans-serif;

html {
  scroll-behavior: smooth;
}

body {
  font-family: $para-font;
  color: $para-color;
  font-size: rem(18px);
  padding: 0;
  margin: 0;
  font-weight: 400;
  line-height: 1.7;
  background-color: $body-bg;
  overflow-x: hidden;
  @extend .position-relative;
  @extend .transition;
  display: flex;
  flex-flow: column;
  min-height: 100vh;
}

footer {
  margin-top: auto;
}

img {
  max-width: 100%;
  height: auto;
  user-select: none;
}
select {
  cursor: pointer;
}
ul,
ol {
  padding: 0;
  margin: 0;
  list-style: none;
}
button {
  cursor: pointer;
}
*:focus {
  outline: none;
}
button {
  border: none;
}
button:focus {
  outline: none;
}
span {
  display: inline-block;
}
a:hover {
  color: $main-color;
}
hr {
  background-color: $border-color;
  opacity: 0.65;
}
/* reset css end */
